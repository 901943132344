<template>
  <div :class="`d-flex flex-column align-items-stretch gap-2`">
    <image-gallery
      v-if="expanded"
      :image-urls="property.imageUrls">
      <template #upper-right>
        <div class="bg-white bg-opacity-75 rounded">
          <icon-button small icon="x-lg" @click="expanded = false"/>
        </div>
      </template>
    </image-gallery>
    <div class="d-flex flex-row w-100">
      <div :class="`d-flex flex-column flex-grow-1`">
        <div class="d-flex flex-row align-items-center">
          <div class="flex-grow-1 me-1">
            <div>{{ property.addressFormatted }}</div>
            <AssessorLink :property="property" class="small text-muted"/>
          </div>
        </div>
        <div v-if="property.owners.length > 0" class="small text-muted">
          <div v-for="owner in property.owners" :key="owner">{{ owner }}</div>
        </div>
        <div class="small text-muted">
          {{ Math.round(100 * distance) / 100 }} miles from listing coordinates
        </div>
        <div v-if="!!license" class="small d-flex flex-row text-muted gap-1 align-items-start">
          <i class="bi-file-earmark-check"/>
          <div>License #{{ license.number }}{{ license.contact.owners.length === 0 ? '' : `: ${license.contact.owners.join(', ')}` }}</div>
        </div>
      </div>
      <b-image
        v-if="!expanded && property.imageUrl"
        :src="property.imageUrl"
        style="cursor: pointer"
        image-class="rounded ms-2"
        @click="expanded = true"
      />
    </div>
    <div v-if="listings.length > 0" class="d-flex flex-column align-items-stretch rounded bg-gray">
      <div class="list-group">
        <div class="list-group-item small">
          {{ formatNumber(listings.length, 'Other listing') }}
        </div>
        <a v-for="listing in listings" :key="listing.id" class="list-group-item list-group-item-action" target="_blank" :href="listing.url">
          <ListingItemView :value="listing" :model="model" hide-address :show-license="false" compact />
        </a>
      </div>
    </div>
    <div v-if="isVerified && (listing.verifiedByUser?.displayName || listing.dateVerified)" class="p-2 rounded bg-gray small">
      <div>
        Verified
        {{ listing.verifiedByUser?.displayName ? ` by ${listing.verifiedByUser?.displayName}` : '' }}
        {{ listing.dateVerified ? ` on ${formatDate(listing.dateVerified)}` : '' }}
      </div>
      <div v-if="listing.verifiedNotes" class="mt-2 gap-2 d-flex flex-row ">
        <i class="bi-chat-text"/>
        <div>
          {{ listing.verifiedNotes }}
        </div>
      </div>
    </div>
    <div>
      <BCollapse :show="!verifying">
        <div class="d-flex flex-row align-items-center justify-content-end">
          <div class="flex-grow-1 me-2 d-flex flex-row align-items-center" v-if="!verifying">
            <a v-if="!isVerified" class="btn btn-primary btn-sm me-2" @click="verifying = true">Verify</a>
            <a v-else class="btn btn-danger btn-sm me-2" @click="verifying = true"><i class="bi-trash"/> Clear</a>
          </div>
          <IconButton v-if="property.realtorUrl" :href="property.realtorUrl" target="_blank" title="Open in Redfin in a new tab">
            <i class="bi-house"/>
          </IconButton>
          <slot name="buttons" />
        </div>
      </BCollapse>
      <BCollapse :show="verifying">
        <div class="d-flex flex-column align-items-stretch small border rounded shadow-sm p-3 gap-2">
          <div>
            <template v-if="isVerified">
              Are you sure you want to clear this address? This will move the listing back to "Needs Attention."
            </template>
            <template v-else>
              Are you sure you want to verify this property?
            </template>
          </div>
          <textarea class="form-control form-control-sm" v-model="notes" placeholder="Notes (optional)" />
          <div class="d-flex flex-row align-items-center justify-content-end mb-3">
            <button type="button" :class="`btn btn-${ isVerified ? 'danger' : 'primary' } btn-sm`" @click.stop="commit">
              Yes, {{ isVerified ? 'clear' : 'verify' }} it
            </button>
            <button type="button" class="ms-3 btn btn-outline-dark btn-sm" @click.stop="verifying = false">
              Cancel
            </button>
          </div>
        </div>
      </BCollapse>
    </div>
  </div>
</template>

<script>
import {Listing} from "@/models/Listing";
import BCollapse from "@/components/common/BCollapse.vue";
import {Property} from "@/models/Property";
import {AppModel} from "@/models/AppModel";
import BImage from "@/components/common/BImage.vue";
import IconButton from "@/components/common/IconButton.vue";
import AssessorLink from "@/components/common/AssessorLink.vue";
import ListingItemView from "@/components/ListingItemView.vue";
import ImageGallery from "@/components/common/ImageGallery.vue";

export default {
  components: {ImageGallery, ListingItemView, AssessorLink, IconButton, BImage, BCollapse},
  emits: ['verify','clear'],
  props: {
    model: AppModel,
    listing: Listing,
    property: Property
  },
  data() {
    return {
      verifying: false,
      expanded: false,
      notes: ''
    };
  },
  computed: {
    isVerified() {
      return this.listing.verifiedPropertyId === this.property.id;
    },
    license() {
      // There's a better way to do this if it can be a promise...licenses are tied directly to properties,
      // so we should be able to call a new property.getLicense() method, which would look up the license
      // by property ID (currently the license_application.parcelId field)
      return this.model.lookupLicenseForProperty(this.property);
    },
    listings() {
      return this.model.lookupListingsForPropertyId(this.property.id);
    },
    distance() {
      const p1 = this.listing.latlng;
      const p2 = this.property.latlng;
      if (!p2) {
        return null;
      }
      // console.log(`Computing distance between ${p1.lat},${p1.lng} and ${p2.lat},${p2.lng}`)
      const R_EARTH = 3958.8;

      const rlat1 = p1.lat * (Math.PI/180); // Convert degrees to radians
      const rlat2 = p2.lat * (Math.PI/180); // Convert degrees to radians
      const difflat = rlat2 - rlat1; // Radian difference (latitudes)
      const difflng = (p2.lng-p1.lng) * (Math.PI/180); // Radian difference (longitudes)

      return 2 * R_EARTH * Math.asin(
        Math.sqrt(
          Math.sin(difflat/2) * Math.sin(difflat/2) +
          Math.cos(rlat1) * Math.cos(rlat2) * Math.sin(difflng/2) * Math.sin(difflng/2)
        ));
    }
  },
  methods: {
    commit() {
      if (this.isVerified) {
        this.$emit('clear', {notes:this.notes});
      } else {
        this.$emit('verify', {property:this.property, notes:this.notes});
      }
    }
  }
}
</script>
